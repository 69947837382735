import {BusinessType} from '../../types/business';

const BusinessCard: React.FC<{business: BusinessType}> = ({business}) => {
  const AWS_S3_BUCKET_BUSINESS =
    'https://coferta-prod.s3.eu-central-1.amazonaws.com/businesses-images/';
  const imageUrl = `${AWS_S3_BUCKET_BUSINESS}${business.photoUrl}`;

  return (
    <div className="mb-1 flex items-center justify-center w-full ">
      <div className="w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none flex">
        <div className="w-1/3 h-48 bg-gray-200 overflow-hidden">
          <img
            src={imageUrl}
            alt={business.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 px-4 flex flex-col  w-2/3">
          <h5 className="text-[#3b3f5c] text-xl font-semibold mb-2 dark:text-white-light">
            {business.name}
          </h5>
          <p className="text-white-dark mb-2">{business.address}</p>
          <p className="text-white-dark">
            Phone: {business.contactInfo.primaryPhoneNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
