import React, {useState} from 'react';
import useBusinessDetails from '../hooks/useBusinessDetails';
import useQrLogs from '../hooks/useQrLogs';
import useLoyaltyCard from '../hooks/useLoyaltyCard';
import LoyaltyCard from '../components/common/LoyaltyCard';
import BusinessCard from '../components/common/BusinessCard';
import ColumnChart from '../components/charts/ColumnChart';
import DonutChart from '../components/charts/DonutChart';
import {useSelector} from 'react-redux';
import {selectBusiness} from '../store/selectors/businessSelectors';
import CustomAlert from '../components/common/CustomAlert';

const Dashboard: React.FC = () => {
  const businessDetails = useBusinessDetails();
  const {weeklyData, totalQrPoints, totalFreeCoffeeCount} = useQrLogs();
  const {
    loyaltyCard,
    handleCreateLoyaltyCard,
    alertMessage,
    alertType,
    setAlertMessage,
  } = useLoyaltyCard();
  const business = useSelector(selectBusiness);
  const [freeThreshold, setFreeThreshold] = useState<number>(10);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const getLast14Days = () => {
    const days = [];
    const today = new Date();
    for (let i = 0; i < 14; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      days.push(date.toLocaleDateString('en-US', {weekday: 'short'}));
    }
    return days.reverse();
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
    setAlertMessage(null);
  };

  const handleCreateCard = async () => {
    await handleCreateLoyaltyCard(freeThreshold);
    setIsAlertOpen(true);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-center items-start space-x-4">
        <div className="w-3/4">
          {businessDetails && <BusinessCard business={businessDetails} />}
        </div>
        <div className="w-1/4">
          <LoyaltyCard
            businessName={business?.name ?? ''}
            freeThreshold={loyaltyCard?.freeThreshold ?? freeThreshold}
            onFreeThresholdChange={setFreeThreshold}
            onCreateLoyaltyCard={handleCreateCard}
            loyaltyCardExists={!!loyaltyCard}
          />
        </div>
      </div>
      <div className="w-full flex flex-row space-x-4">
        <ColumnChart
          title="CO Puanları ve Ücretsiz Kahve Dağılımı"
          series={[
            {
              name: 'CO Points',
              data: weeklyData.qrPoints,
            },
            {
              name: 'Free Coffee Counts',
              data: weeklyData.freeCoffeeCounts,
            },
          ]}
          categories={getLast14Days()}
        />
        <DonutChart
          title="Toplam Puan ve Ücretsiz Kahve"
          series={[totalQrPoints, totalFreeCoffeeCount]}
        />
      </div>
      <CustomAlert
        isOpen={isAlertOpen}
        onClose={handleAlertClose}
        onConfirm={handleAlertClose}
        title={alertType === 'success' ? 'Başarılı' : 'Hata'}
        message={alertMessage || ''}
        confirmText="Tamam"
        showCancelButton={false}
      />
    </div>
  );
};

export default Dashboard;
