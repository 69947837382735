import React, {useEffect, useRef} from 'react';
import axios from 'axios';

interface PlaceDetails {
  name: string;
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

interface MapProps {
  onSelectLocation: (location: {
    lat: number;
    lng: number;
    address: string;
    placeId: string;
    name: string;
  }) => void;
}

const Map: React.FC<MapProps> = ({onSelectLocation}) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    if (!API_URL || !API_KEY) {
      console.error(
        'API_URL or API_KEY is missing in the environment variables.',
      );
      return;
    }

    const map = new window.google.maps.Map(mapRef.current as HTMLElement, {
      center: {lat: 41.0391643, lng: 29.0004594},
      zoom: 12,
    });

    map.addListener('click', async (event: any) => {
      const lat = event.latLng?.lat();
      const lng = event.latLng?.lng();
      const place_id = event.placeId;

      if (lat === undefined || lng === undefined || place_id === undefined) {
        console.error('Could not retrieve location details from event.');
        return;
      }

      try {
        const placeDetailsResponse = await axios.get<{
          data: PlaceDetails;
        }>(`${API_URL}auth/get-place-details`, {
          params: {
            placeId: place_id,
          },
          headers: {
            'api-key': API_KEY,
          },
        });

        const placeDetails = placeDetailsResponse.data.data;

        const {name, formatted_address, geometry} = placeDetails;

        onSelectLocation({
          lat: geometry.location.lat,
          lng: geometry.location.lng,
          address: formatted_address,
          placeId: place_id,
          name: name,
        });
      } catch (error) {
        console.error('Error fetching place details:', error);
      }
    });
  }, [onSelectLocation, API_URL, API_KEY]);

  return <div ref={mapRef} style={{width: '100%', height: '400px'}} />;
};

export default Map;
