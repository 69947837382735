import React from 'react';

import Logo32 from '../../assets/images/logo/logo-32.png';
import Logo33 from '../../assets/images/logo/logo-33.png';
import Logo34 from '../../assets/images/logo/logo-34.png';
import Logo35 from '../../assets/images/logo/logo-35.png';
import Logo36 from '../../assets/images/logo/logo-36.png';
import Logo37 from '../../assets/images/logo/logo-37.png';
import Logo38 from '../../assets/images/logo/logo-38.png';

const BrandImages = [
  {src: Logo32, alt: 'logo-32'},
  {src: Logo33, alt: 'logo-33'},
  {src: Logo34, alt: 'logo-34'},
  {src: Logo35, alt: 'logo-35'},
  {src: Logo36, alt: 'logo-36'},
  {src: Logo37, alt: 'logo-37'},
  {src: Logo38, alt: 'logo-38'},
];

const BrandThree = () => {
  return (
    <>
      {BrandImages.map((val, i) => (
        <div
          className="logo d-flex align-items-center justify-content-center"
          key={i}>
          <img src={val.src} alt={val.alt} />
        </div>
      ))}
    </>
  );
};

export default BrandThree;
