import React from 'react';

const FeatureContent = [
  {
    icon: '20',
    meta: 'Dijital aidiyet kart',
    subTitle: `İşletmenize özel aidiyet kartınızı oluşturun ve kullanıcı sadakatini artırın.`,
    dataDelay: '0',
  },
  {
    icon: '21',
    meta: 'Kampanyalar ve duyurular',
    subTitle: `Aidiyet kartı sahiplerine özel kampanyalar oluşturun ve duyurular yapın.`,
    dataDelay: '100',
  },
  {
    icon: '22',
    meta: 'Harita',
    subTitle: `İşletmenizin konumunu harita üzerinde gösterin ve kullanıcıların size kolayca ulaşmasını sağlayın.`,
    dataDelay: '300',
  },
];

const FeatureFour = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}>
          <div
            className="block-style-five"
            style={{

            }}>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFour;
