import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface DonutChartProps {
  series: number[];
  title: string;
}

const DonutChart: React.FC<DonutChartProps> = ({series, title}) => {
  const options = {
    chart: {
      height: 300,
      type: 'donut' as 'donut',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: false,
    },
    labels: ['Total CO Points', 'Total Free'],
    colors: ['#4361ee', '#e7515a'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    legend: {
      position: 'bottom' as 'bottom',
    },
  };

  return (
    <div className="panel mt-6 w-1/5">
      <h6 className="flex justify-center font-extrabold mt-2 mb-2 font-xl">
        {title}
      </h6>
      {series.every(value => value === 0) ? (
        <p>Veri bulunamadı.</p>
      ) : (
        <ReactApexChart
          series={series}
          options={options}
          className="rounded-lg bg-white dark:bg-black overflow-hidden"
          type="donut"
          height={300}
        />
      )}
    </div>
  );
};

export default DonutChart;
