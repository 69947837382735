import {useState, useEffect} from 'react';
import {CofertaAPI} from '../services';
import {QRLog} from '../types/qrlog';
import {useSelector} from 'react-redux';
import {
  selectBusinessToken,
  selectBusiness,
} from '../store/selectors/businessSelectors';

const useQrLogs = () => {
  const [qrLogs, setQrLogs] = useState<QRLog[] | undefined>([]);
  const [weeklyData, setWeeklyData] = useState<{
    qrPoints: number[];
    freeCoffeeCounts: number[];
  }>({
    qrPoints: new Array(14).fill(0),
    freeCoffeeCounts: new Array(14).fill(0),
  });
  const [totalQrPoints, setTotalQrPoints] = useState<number>(0);
  const [totalFreeCoffeeCount, setTotalFreeCoffeeCount] = useState<number>(0);
  const [userStats, setUserStats] = useState<{
    [userId: string]: {
      totalPoints: number;
      freeCoffeeCount: number;
      logs: QRLog[];
    };
  }>({});
  const token = useSelector(selectBusinessToken);
  const business = useSelector(selectBusiness);

  useEffect(() => {
    if (!token || !business) {
      return;
    }

    const fetchQrLogs = async () => {
      try {
        const response = await CofertaAPI.getQrLogs(token, {
          businessId: business._id,
        });
        if (response.status === 200 && response.data) {
          setQrLogs(response.data);
          processWeeklyData(response.data);
          processUserStats(response.data);
        } else {
          console.error('QR loglarını alırken bir hata oluştu:', response);
        }
      } catch (error) {
        console.error('QR loglarını alırken bir hata oluştu:', error);
      }
    };

    fetchQrLogs();
  }, [token, business]);

  const processWeeklyData = (logs: QRLog[]) => {
    const qrPoints = new Array(14).fill(0);
    const freeCoffeeCounts = new Array(14).fill(0);

    let qrSum = 0;
    let freeCoffeeSum = 0;

    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0)); // Sadece tarih kısmını alıyoruz

    logs.forEach(log => {
      const logDate = new Date(new Date(log.date).setHours(0, 0, 0, 0)); // Sadece tarih kısmını alıyoruz
      const dayDifference = Math.floor(
        (today.getTime() - logDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      if (dayDifference >= 0 && dayDifference < 14) {
        if (log.transactionType === 'POINTS_AWARDED') {
          qrPoints[13 - dayDifference] += log.addedPoints; // Bugünden geçmişe doğru sıralama
          qrSum += log.addedPoints;
        }
        if (log.transactionType === 'FREE_COFFEE') {
          freeCoffeeCounts[13 - dayDifference] += 1; // Bugünden geçmişe doğru sıralama
          freeCoffeeSum += 1;
        }
      }
    });

    setWeeklyData({qrPoints, freeCoffeeCounts});
    setTotalQrPoints(qrSum);
    setTotalFreeCoffeeCount(freeCoffeeSum);
  };

  const processUserStats = (logs: QRLog[]) => {
    const stats: {
      [userId: string]: {
        totalPoints: number;
        freeCoffeeCount: number;
        logs: QRLog[];
      };
    } = {};
    logs.forEach(log => {
      if (!stats[log.userId]) {
        stats[log.userId] = {totalPoints: 0, freeCoffeeCount: 0, logs: []};
      }
      stats[log.userId].logs.push(log);
      if (
        log.transactionType === 'POINTS_AWARDED' ||
        log.transactionType === 'FREE_COFFEE'
      ) {
        stats[log.userId].totalPoints += log.addedPoints;
      }
      if (log.transactionType === 'FREE_COFFEE') {
        stats[log.userId].freeCoffeeCount += 1;
      }
    });
    setUserStats(stats);
  };

  return {qrLogs, weeklyData, totalQrPoints, totalFreeCoffeeCount, userStats};
};

export default useQrLogs;
