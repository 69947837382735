import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqContent = [
  {
    title: 'Uygulama ücretli mi?',
    desc: `Kullanıcılar için coferta ücretsizdir. İşletmeler için ise XX ve ZZ olmak üzere iki farklı paket sunulmaktadır.`,
    expand: 'a',
  },
  {
    title: 'Hangi uygulama platformlarından ulaşılabilir?',
    desc: `Coferta, Google Play Store ve App Store’da bulunmaktadır. "Coferta" yazarak uygulamamıza ulaşabilirsiniz.`,
    expand: 'b',
  },
  {
    title: 'Sadece İstanbul’da mı kullanılıyor?',
    desc: `Şu anda sadece İstanbul’da hizmet vermekteyiz, ancak çok yakında diğer şehirlerde de faaliyete geçeceğiz.`,
    expand: 'c',
  },
  {
    title: 'Kullanıcıya ait hangi veriler kullanılmaktadır?',
    desc: `Kullanıcılardan yalnızca telefon numarası veya e-posta adresi gerekmektedir. (Bu kısımda revize yapabilirsiniz)`,
    expand: 'd',
  },
];

const FaqContentTwo = [
  {
    title: 'Nasıl coferta’lı olabilirim?',
    desc: `Web sitemizin sağ üst köşesinde bulunan "Şimdi Başla" butonuna tıklayarak hızlıca coferta’lı olabilirsiniz!`,
    expand: 'e',
  },
  {
    title: 'Kayıt olunduktan sonraki süreç nasıl işliyor?',
    desc: `Paylaştığınız bilgiler doğrultusunda ekibimiz sizinle iletişime geçecek ve işletmeniz için coferta kurulumu tamamlanacaktır.`,
    expand: 'f',
  },
  {
    title: 'Nasıl iletişime geçebilirim?',
    desc: `info@coferta.com adresinden bize ulaşabilirsiniz.`,
    expand: 'g',
  },
  {
    title: 'Müşterilerden ödemeyi coferta mı alıyor?',
    desc: `Hayır. Uygulama içinde cüzdan bulunmamaktadır. Tüm ödeme süreçleri işletme ve müşteri arasında gerçekleşmektedir.`,
    expand: 'h',
  },
  {
    title: 'İşletmeye bir cihaz mı kurulacak?',
    desc: `Hayır. Uygulamayı indirdikten sonra “İşletme Girişi” butonuna tıklayarak hızlıca kurulumu tamamlayabilirsiniz.`,
    expand: 'i',
  },
];

const FaqClassic = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <div className="row">
          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContent.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{' '}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p
                          style={{
                            marginTop: '30px',
                          }}>
                          {item.desc}
                        </p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
          {/* End.col */}

          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContentTwo.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{' '}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p
                          style={{
                            marginTop: '30px',
                          }}>
                          {item.desc}
                        </p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqClassic;
