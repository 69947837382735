import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPageTitle} from '../store/themeConfigSlice';
import {CofertaAPI} from '../services';
import {
  selectBusiness,
  selectBusinessToken,
} from '../store/selectors/businessSelectors';
import CustomAlert from '../components/common/CustomAlert';
import {CreateCampaignRequest} from '../services/payloadType';

const CampaignScreen = () => {
  const dispatch = useDispatch();
  useState(() => {
    dispatch(setPageTitle('Create Campaign'));
  });

  const token = useSelector(selectBusinessToken);
  const business = useSelector(selectBusiness);

  const [formData, setFormData] = useState<Record<string, any>>({
    campaignName: '',
    description: '',
    startDate: '',
    endDate: '',
    discountAmount: undefined,
    discountType: '',
    limitedUsage: false,
    maxUsage: undefined,
    specialNotes: '',
    isBirthdayCampaign: false,
  });

  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [images, setImages] = useState<FileList | null>(null);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const {name, value, type} = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    const checked =
      type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImages(e.target.files);
  };

  const validate = () => {
    const newErrors: {[key: string]: string} = {};

    if (
      !formData.campaignName ||
      formData.campaignName.length < 3 ||
      formData.campaignName.length > 50
    ) {
      newErrors.campaignName =
        'Campaign Name must be between 3 and 50 characters';
    }

    if (
      !formData.description ||
      formData.description.length < 10 ||
      formData.description.length > 500
    ) {
      newErrors.description =
        'Description must be between 10 and 500 characters';
    }

    if (!formData.startDate) {
      newErrors.startDate = 'Start Date is required';
    }

    if (!formData.endDate) {
      newErrors.endDate = 'End Date is required';
    } else if (formData.startDate && formData.endDate < formData.startDate) {
      newErrors.endDate = 'End Date cannot be before Start Date';
    }

    if (formData.discountAmount !== undefined && formData.discountAmount <= 0) {
      newErrors.discountAmount = 'Discount Amount must be a positive number';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      try {
        const imagesBase64: string[] = [];

        if (images) {
          const imagePromises = Array.from(images).map(
            image =>
              new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result as string);
                };
                reader.onerror = reject;
                reader.readAsDataURL(image);
              }),
          );

          const results = await Promise.all(imagePromises);
          imagesBase64.push(...results);
        }

        const createCampaignData: CreateCampaignRequest = {
          campaignName: formData.campaignName,
          description: formData.description,
          startDate: new Date(formData.startDate),
          endDate: new Date(formData.endDate),
          discountAmount: formData.discountAmount
            ? Number(formData.discountAmount)
            : undefined,
          discountType: formData.discountType || undefined,
          businessId: business?._id || '',
          limitedUsage: formData.limitedUsage,
          maxUsage: formData.maxUsage ? Number(formData.maxUsage) : undefined,
          specialNotes: formData.specialNotes || undefined,
          isBirthdayCampaign: formData.isBirthdayCampaign,
          images: imagesBase64,
        };

        const API_KEY = process.env.REACT_APP_API_KEY;

        const headers: any = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': API_KEY,
          Authorization: `Bearer ${token}`,
        };

        const response = await fetch('https://api.coferta.com/api/campaign', {
          method: 'POST',
          body: JSON.stringify(createCampaignData),
          headers,
        });

        if (response.ok) {
          const data = await response.json();
          setAlertMessage('Form submitted successfully');
          setAlertType('success');
          setFormData({
            campaignName: '',
            description: '',
            startDate: '',
            endDate: '',
            discountAmount: undefined,
            discountType: '',
            limitedUsage: false,
            maxUsage: undefined,
            specialNotes: '',
            isBirthdayCampaign: false,
          });
          setIsAlertOpen(true);
        } else {
          const errorData = await response.json();
          setAlertMessage(`Error submitting form: ${errorData.message}`);
          setAlertType('error');
          setIsAlertOpen(true);
        }
      } catch (error) {
        setAlertMessage('Error submitting form');
        setAlertType('error');
        setIsAlertOpen(true);
      }
    }
  };
  const handleAlertClose = () => {
    setIsAlertOpen(false);
    setAlertMessage(null);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cover bg-center">
      <div className="panel sm:w-[480px] m-6 max-w-lg w-full">
        <div className="flex items-center justify-between mb-5">
          <h5 className="font-semibold text-lg">Create Campaign</h5>
        </div>
        <div className="mb-5">
          <form
            onSubmit={handleSubmit}
            className="space-y-4"
            encType="multipart/form-data">
            <div>
              <label
                htmlFor="campaignName"
                className="block text-sm font-medium text-gray-700">
                Campaign Name <span className="text-red-500">*</span>
              </label>
              <input
                id="campaignName"
                name="campaignName"
                type="text"
                className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.campaignName}
                onChange={handleChange}
                required
              />
              {errors.campaignName && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.campaignName}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700">
                Description <span className="text-red-500">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                className="form-textarea mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.description}
                onChange={handleChange}
                required
              />
              {errors.description && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.description}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700">
                Start Date <span className="text-red-500">*</span>
              </label>
              <input
                id="startDate"
                name="startDate"
                type="date"
                className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
              {errors.startDate && (
                <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700">
                End Date <span className="text-red-500">*</span>
              </label>
              <input
                id="endDate"
                name="endDate"
                type="date"
                className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.endDate}
                onChange={handleChange}
                required
              />
              {errors.endDate && (
                <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="discountAmount"
                className="block text-sm font-medium text-gray-700">
                Discount Amount
              </label>
              <input
                id="discountAmount"
                name="discountAmount"
                type="number"
                className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.discountAmount}
                onChange={handleChange}
              />
              {errors.discountAmount && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.discountAmount}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="discountType"
                className="block text-sm font-medium text-gray-700">
                Discount Type
              </label>
              <select
                id="discountType"
                name="discountType"
                className="form-select mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.discountType}
                onChange={handleChange}>
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed</option>
                <option value="">None</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="images"
                className="block text-sm font-medium text-gray-700">
                Images <span className="text-red-500">*</span>
              </label>
              <input
                id="images"
                name="images"
                type="file"
                className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                onChange={handleFileChange}
                multiple
                required
              />
            </div>
            <div className="flex items-center">
              <input
                id="limitedUsage"
                name="limitedUsage"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                checked={formData.limitedUsage}
                onChange={handleChange}
              />
              <label
                htmlFor="limitedUsage"
                className="ml-2 block text-sm leading-5 text-gray-900">
                Limited Usage
              </label>
            </div>
            {formData.limitedUsage && (
              <div>
                <label
                  htmlFor="maxUsage"
                  className="block text-sm font-medium text-gray-700">
                  Max Usage
                </label>
                <input
                  id="maxUsage"
                  name="maxUsage"
                  type="number"
                  className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  value={formData.maxUsage}
                  onChange={handleChange}
                />
              </div>
            )}
            <div>
              <label
                htmlFor="specialNotes"
                className="block text-sm font-medium text-gray-700">
                Special Notes
              </label>
              <textarea
                id="specialNotes"
                name="specialNotes"
                className="form-textarea mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                value={formData.specialNotes}
                onChange={handleChange}
              />
            </div>
            <div className="flex items-center">
              <input
                id="isBirthdayCampaign"
                name="isBirthdayCampaign"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                checked={formData.isBirthdayCampaign}
                onChange={handleChange}
              />
              <label
                htmlFor="isBirthdayCampaign"
                className="ml-2 block text-sm leading-5 text-gray-900">
                Is Birthday Campaign
              </label>
            </div>
            <button type="submit" className="btn btn-primary mt-6 w-full">
              Create Campaign
            </button>
          </form>
        </div>
      </div>
      <CustomAlert
        isOpen={isAlertOpen}
        onClose={handleAlertClose}
        onConfirm={handleAlertClose}
        title={alertType === 'success' ? 'Başarılı' : 'Hata'}
        message={alertMessage || ''}
        confirmText="Tamam"
        showCancelButton={false}
      />
    </div>
  );
};

export default CampaignScreen;
