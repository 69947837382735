import React from 'react';
import {Helmet} from 'react-helmet';
import Header from './HeaderThree';
import Footer from './FooterThree';
import CopyRight from './CopyRight';
import ScrollspyNav from 'react-scrollspy-nav';

const TermsConditions = () => {
  return (
    <div
      className="doc-container main-page-wrapper"
      style={{
        paddingTop: '10px',
      }}>
      <Helmet>
        <title>Genel Hükümler ve Koşullar || Coferta</title>
      </Helmet>
      <Header />
      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={[
              'introduction',
              'scope',
              'contract_formation',
              'pre_audit',
              'costs',
              'withdrawal_right',
              'user_cancellation',
              'coferta_cancellation',
              'user_rights',
            ]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300">
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#introduction">
                      1. Giriş
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#scope">
                      2. Uygulama Kapsamı
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contract_formation">
                      3. Sözleşmenin Kurulması
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pre_audit">
                      4. Ön Denetim
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#costs">
                      5. Maliyetler
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#withdrawal_right">
                      6. Geri Çekme Hakkı
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#user_cancellation">
                      7. Kullanıcı Hesabın İptali
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#coferta_cancellation">
                      8. Coferta Hesabın İptali
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#user_rights">
                      9. Hak ve Yükümlülükler
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8">
                <div className="tab-content">
                  <div id="introduction">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Giriş
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Biz, Ahmet Emre Parmaksız, Kocasinan Caddesi Atilla
                      Sokak No:6, Türkiye, Coferta isimli dijital bir platformu
                      yönetiyoruz (bundan böyle "biz" veya "Coferta" olarak
                      anılacaktır). Coferta, işletmelerin müşteri bağlılığını
                      artırmalarına ve müşterilere daha kolay ulaşmalarına
                      yardımcı olur.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Coferta, kullanıcıların işletmeleri liste halinde
                      görebilmesini, işletmelerin oluşturduğu loyalty kartlarına
                      göre QR kodu okutarak alışverişlerini kaydedebilmesini
                      sağlar. Uygulama, kullanıcıların aidiyet kartlarını
                      doldurmalarına yardımcı olur.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Bu şartlara ve koşullara aykırı davranışlar tespit
                      edildiğinde, hesapları ve içerikleri inceleme hakkımız
                      saklıdır. Bu şartların ihlali durumunda hesabınızı
                      sonlandırma, erişimi kısıtlama veya diğer önlemleri alma
                      hakkımızı saklı tutarız.
                    </p>
                  </div>
                  <div id="scope">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      Uygulama Kapsamı
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Bu şartlar ve koşullar, kullanım sırasında geçerli
                      olan versiyonlarıyla münhasıran geçerlidir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Çelişen genel şartlar ve koşullar uygulanmaz, biz
                      bunlardan haberdar olsak bile, yazılı olarak açıkça
                      onaylanmadıkça.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Coferta platformunun diğer kullanıcıları ile
                      aranızdaki sözleşmesel ilişkilere hukuki olarak dahil
                      değiliz.
                    </p>
                  </div>
                  <div id="contract_formation">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      Sözleşmenin Kurulması
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Coferta teklifini kullanabilmek için en az 13 yaşında
                      olmalı ve bir profil oluşturmalısınız.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Coferta'yı kullanmak, bu GTC ve Topluluk Kurallarını
                      kabul etmek anlamına gelir. Kayıt sürecinde bu form
                      alanını onaylayarak, GTC'yi kabul ettiğinizi ve kullanıcı
                      sözleşmesinin bağlayıcı bir parçası olarak tanıdığınızı
                      beyan edersiniz.
                    </p>
                  </div>
                  <div id="pre_audit">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      Ön Denetim
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Coferta, kullanıcıların alışverişlerini kaydettiği ve
                      işletmelerin loyalty kartları oluşturduğu bir platformdur.
                      İçeriğinizi veya diğer Coferta kullanıcılarının içeriğini
                      önceden inceleme zorunluluğumuz yoktur. Ancak,
                      gerektiğinde içerikleri inceleme, reddetme ve/veya
                      kaldırma hakkımızı saklı tutarız.
                    </p>
                  </div>
                  <div id="costs">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      Maliyetler
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Kayıt ve bazı temel özellikler Coferta tarafından
                      ücretsiz olarak sağlanmaktadır.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Coferta ayrıca işletmeler için ücretli abonelikler
                      sunar. Bu aboneliklerin koşulları ve fiyatları açıkça
                      belirtilmiştir. İşletmeler bu abonelikler için aylık ücret
                      öder.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Abonelikler otomatik olarak yenilenir.
                      Aboneliklerinizi iptal etmediğiniz sürece aynı fiyat
                      üzerinden yenilenir.
                    </p>
                  </div>
                  <div id="withdrawal_right">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      {/* GERİ ÇEKME HAKKI / TÜKETİCİLER İÇİN İPTAL POLİTİKASI */}
                      Geri Çekme Hakkı / Tüketiciler İçin İptal Politikası
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Bu sözleşmeyi on dört gün içinde herhangi bir neden
                      göstermeksizin iptal etme hakkınız vardır.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Çekilme süresi, sözleşmenin kurulmasından itibaren on
                      dört gündür. Çekilme hakkınızı kullanmak için bizimle
                      aşağıdaki bilgilerle iletişime geçmelisiniz:
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Ahmet Emre Parmaksız
                      <br />
                      Kocasinan Caddesi Atilla Sokak No:6
                      <br />
                      Türkiye
                      <br />
                      info@coferta.com
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) İptal süresine uymak için, iptal bildirimini iptal
                      süresi dolmadan göndermeniz yeterlidir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (4) İptal formu aşağıdaki şekilde kullanılabilir:
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Örnek iptal formu
                      <br />
                      Ahmet Emre Parmaksız, Kocasinan Caddesi Atilla Sokak No:6,
                      info@coferta.com
                      <br />
                      Satın aldığım ürünlerin/hizmetlerin iptali için sözleşmemi
                      iptal ediyorum.
                      <br />
                      Sipariş edilen
                      <br />
                      Teslim alınan
                      <br />
                      Tüketicinin adı
                      <br />
                      Tüketicinin adresi
                      <br />
                      Tüketicinin imzası (kağıt iletişimde)
                      <br />
                      Tarih
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (5) İptal etmeniz durumunda, ödediğiniz tüm ücretleri ve
                      teslimat masraflarını (standart teslimat dışında ek
                      maliyetler hariç) en geç on dört gün içinde iade edeceğiz.
                      Ödeme, aynı yöntemle yapılacaktır.
                    </p>
                  </div>
                  <div id="user_cancellation">
                    <h2
                      className="font-sans"
                      style={{fontWeight: 'bold', marginBottom: '20px'}}>
                      {/* KULLANICI TARAFINDAN HESABIN İPTALİ */}
                      Kullanıcı Tarafından Hesabın İptali
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Hesabınızı istediğiniz zaman bildirim yapmadan iptal
                      edebilirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Hesabınızı uygulama içindeki profil sekmesinden
                      silebilirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Hesabınızı iptal ettiğinizde, ücretli hizmetler de
                      sona erecektir. Tüm içerikler ve konuşmalar silinir.
                    </p>
                  </div>
                  <div id="coferta_cancellation">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      {/* COFERTA TARAFINDAN HESABIN İPTALİ */}
                      Coferta Tarafından Hesabın İptali
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Hesabınızı iki hafta önceden bildirim yaparak iptal
                      edebiliriz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Hesap, sizin kontrolünüz dışında kapatıldığında,
                      kullanmadığınız ücretli hizmetler için geri ödeme
                      yapılabilir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Teklifin artık sürdürülememesi durumunda (iflas vb.),
                      ücretli hizmetler için geri ödeme yapılmaz.
                    </p>
                  </div>
                  <div id="user_rights">
                    <h2
                      style={{fontWeight: 'bold', marginBottom: '20px'}}
                      className="font-sans">
                      {/* KULLANICININ HAK VE YÜKÜMLÜLÜKLERİ */}
                      Kullanıcının Hak ve Yükümlülükleri
                    </h2>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (1) Diğer kullanıcıların gizliliğine saygı gösterir, sahte
                      kimlik kullanmaz ve dolandırıcılık yapmazsınız. Gerçek
                      adınızı ve yaşınızı kullanarak tek bir hesap
                      oluşturabilirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (2) Hesabınıza üçüncü kişilerin erişmesine izin vermez ve
                      üçüncü bir kişi hesabınıza erişirse hemen bize
                      bildirirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (3) Ayrıca, şunları kabul edersiniz:
                      <ul>
                        <li>
                          Yasa dışı veya profesyonel olmayan davranışlarda
                          bulunmamak ve saldırgan veya ayrımcı olmamak.
                        </li>
                        <li>
                          Kimliğinizi, yaşınızı veya niteliklerinizi yanlış
                          tanıtmamak.
                        </li>
                        <li>İzin verilmeyen bilgileri ifşa etmemek.</li>
                        <li>
                          Piramit şemaları veya dolandırıcılık faaliyetleri
                          yürütmemek.
                        </li>
                        <li>
                          Uygulamadan bilgi kazımak veya toplamak için yazılım,
                          cihaz veya yöntemler kullanmamak.
                        </li>
                      </ul>
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (4) Bize sağladığınız tüm bilgileri kullanma hakkını bize
                      devredersiniz. Bu bilgi, hizmetlerimizi iyileştirmek için
                      kullanılabilir ve devredilebilir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (5) Tüm içerik, grafikler, kullanıcı arayüzleri, ticari
                      markalar, logolar ve diğer fikri mülkiyet bize veya üçüncü
                      kişilere aittir. İçeriği yalnızca yasal amaçlarla
                      kullanabilirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (6) Diğer kullanıcıların bilgilerini yalnızca Coferta'nın
                      amacı doğrultusunda kullanabilirsiniz.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (7) Uygulamanın sürekli olarak erişilebilir olması garanti
                      edilmez. Erişilebilirlik oranı %95'ten fazla hedeflenir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (8) Yayınlanan içeriğin doğruluğu ve sunumu garanti
                      edilmez. Diğer kullanıcıların içeriklerinden doğan yasal
                      sonuçlardan sorumlu değiliz. Ancak, üçüncü şahısların
                      iddialarına karşı sizi savunma yükümlülüğümüz yoktur.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (9) İçeriklerin türü ve konumlandırılması üzerinde talep
                      hakkınız yoktur.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (10) İçerik ve kullanıcı içeriklerinin devamlılığı garanti
                      edilmez.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      (11) Hizmetlerin ve içeriklerin değiştirilmesi veya
                      kaldırılması hakkımız saklıdır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>
      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
        </div>
        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsConditions;
