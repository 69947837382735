import React from 'react';
import {Helmet} from 'react-helmet';
import Header from './HeaderThree';
import Footer from './FooterThree';
import CopyRight from './CopyRight';
import ScrollspyNav from 'react-scrollspy-nav';

const PrivacyPolicy = () => {
  return (
    <div
      className="doc-container main-page-wrapper"
      style={{
        paddingTop: '10px',
      }}>
      <Helmet>
        <title>Gizlilik Politikası || Coferta</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Privacy Policy
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={[
              'introduction',
              'definitions',
              'data_collection',
              'data_usage',
              'data_storage',
              'data_disclosure',
            ]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300">
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#introduction">
                      1. Giriş
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#definitions">
                      2. Tanımlar
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#data_collection">
                      3. Kişisel Verilerin Toplanması
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#data_usage">
                      4. Kişisel Verilerin Kullanımı
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#data_storage">
                      5. Kişisel Verilerin Saklanması
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#data_disclosure">
                      6. Kişisel Verilerin Açıklanması
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content">
                  <div id="introduction">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Gizlilik Politikası
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Bu Gizlilik Politikası, Hizmeti kullanırken bilgilerinizi
                      toplama, kullanma ve açıklama ile ilgili politikalarımızı
                      ve prosedürlerimizi açıklar ve gizlilik haklarınız ve
                      yasanın sizi nasıl koruduğu hakkında bilgi verir.
                    </p>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Hizmeti sağlamak ve geliştirmek için Kişisel verilerinizi
                      kullanıyoruz. Hizmeti kullanarak, bu Gizlilik Politikasına
                      uygun olarak bilgilerin toplanmasını ve kullanılmasını
                      kabul etmiş olursunuz. Bu Gizlilik Politikası, Gizlilik
                      Politikası Oluşturucu yardımıyla oluşturulmuştur.
                    </p>
                  </div>
                  <div id="definitions">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Tanımlar
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Bu Gizlilik Politikasının amaçları doğrultusunda:
                    </p>
                    <ul>
                      <li>
                        <strong>Hesap:</strong> Hizmetimize veya Hizmetimizin
                        bir bölümüne erişmek için sizin için oluşturulan
                        benzersiz bir hesabı ifade eder.
                      </li>
                      <li>
                        <strong>Bağlı Kuruluş:</strong> Bir tarafı kontrol eden,
                        taraf tarafından kontrol edilen veya taraf ile ortak
                        kontrol altında olan bir kuruluş anlamına gelir.
                        "Kontrol", yöneticilerin veya diğer yönetim otoritesinin
                        seçimi için oy kullanma hakkına sahip hisse
                        senetlerinin, öz sermaye menfaatlerinin veya diğer
                        menkul kıymetlerin %50 veya daha fazlasına sahip olmayı
                        ifade eder.
                      </li>
                      <li>
                        <strong>Uygulama:</strong> Şirket tarafından sağlanan
                        coferta adlı yazılım programını ifade eder.
                      </li>
                      <li>
                        <strong>Şirket:</strong> Bu Sözleşmede "Şirket", "Biz",
                        "Bizi" veya "Bizim" olarak anılacaktır, coferta'yı ifade
                        eder.
                      </li>
                      <li>
                        <strong>Ülke:</strong> Türkiye'yi ifade eder.
                      </li>
                      <li>
                        <strong>Cihaz:</strong> Bir bilgisayar, cep telefonu
                        veya dijital tablet gibi Hizmete erişebilen herhangi bir
                        cihaz anlamına gelir.
                      </li>
                      <li>
                        <strong>Kişisel Veri:</strong> Tanımlanmış veya
                        tanımlanabilir bir bireyle ilgili herhangi bir bilgiyi
                        ifade eder.
                      </li>
                      <li>
                        <strong>Hizmet:</strong> Uygulamayı ifade eder.
                      </li>
                      <li>
                        <strong>Hizmet Sağlayıcı:</strong> Şirket adına verileri
                        işleyen herhangi bir gerçek veya tüzel kişiyi ifade
                        eder. Şirket tarafından Hizmeti kolaylaştırmak, Şirket
                        adına Hizmeti sağlamak, Hizmetle ilgili hizmetleri
                        gerçekleştirmek veya Hizmetin nasıl kullanıldığını
                        analiz etmede Şirkete yardımcı olmak için istihdam
                        edilen üçüncü taraf şirketleri veya bireyleri ifade
                        eder.
                      </li>
                      <li>
                        <strong>Kullanım Verileri:</strong> Hizmetin kullanımı
                        veya Hizmet altyapısı tarafından otomatik olarak
                        toplanan verileri ifade eder (örneğin, bir sayfa
                        ziyaretinin süresi).
                      </li>
                      <li>
                        <strong>Sen:</strong> Hizmete erişen veya Hizmeti
                        kullanan bireyi veya bu tür bir bireyin adına Hizmete
                        erişen veya Hizmeti kullanan şirketi veya diğer tüzel
                        kişiyi ifade eder.
                      </li>
                    </ul>
                  </div>
                  <div id="data_collection">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Kişisel Verilerin Toplanması
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Hizmetimizi kullanırken, sizi iletişim kurmak veya
                      tanımlamak için kullanılabilecek bazı kişisel olarak
                      tanımlanabilir bilgileri bize sağlamanızı isteyebiliriz.
                      Kişisel olarak tanımlanabilir bilgiler şunları içerebilir,
                      ancak bunlarla sınırlı değildir:
                    </p>
                    <ul>
                      <li>E-posta adresi</li>
                      <li>Telefon numarası</li>
                    </ul>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Kullanım Verileri, Hizmeti kullanırken otomatik olarak
                      toplanır. Kullanım Verileri, Cihazınızın İnternet
                      Protokolü adresi (örneğin IP adresi), tarayıcı türü,
                      tarayıcı sürümü, Hizmetimizin ziyaret ettiğiniz sayfaları,
                      ziyaretinizin saati ve tarihi, bu sayfalarda geçirilen
                      süre, benzersiz cihaz tanımlayıcıları ve diğer tanılama
                      verilerini içerebilir.
                    </p>
                  </div>
                  <div id="data_usage">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Kişisel Verilerin Kullanımı
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Şirket, Kişisel Verileri aşağıdaki amaçlarla kullanabilir:
                    </p>
                    <ul>
                      <li>
                        Hizmetimizi sağlamak ve sürdürmek, Hizmetimizin
                        kullanımını izlemek dahil.
                      </li>
                      <li>
                        Hesabınızı yönetmek: Hizmetin bir kullanıcısı olarak
                        kaydınızı yönetmek için. Sağladığınız Kişisel Veriler,
                        kayıtlı bir kullanıcı olarak Hizmetin çeşitli
                        işlevlerine erişmenizi sağlayabilir.
                      </li>
                      <li>
                        Bir sözleşmenin ifası için: Satın aldığınız ürünlerin,
                        öğelerin veya hizmetlerin satın alma sözleşmesinin
                        geliştirilmesi, uyumluluğu ve uygulanması veya Hizmet
                        aracılığıyla Bizimle yapılan diğer herhangi bir
                        sözleşmenin.
                      </li>
                      <li>
                        Sizinle iletişim kurmak için: E-posta, telefon
                        aramaları, SMS veya mobil uygulamanın push bildirimleri
                        gibi diğer eşdeğer elektronik iletişim biçimleriyle
                        işlevsellikler, ürünler veya sözleşmeli hizmetlerle
                        ilgili güncellemeler veya bilgilendirici iletişimler
                        hakkında size ulaşmak.
                      </li>
                      <li>
                        Sizi bilgilendirmek için: Sizlere, daha önce satın
                        aldığınız veya bilgi talep ettiğiniz ürünlere benzer
                        diğer ürünler, hizmetler ve etkinlikler hakkında genel
                        bilgiler sunmak.
                      </li>
                      <li>
                        Taleplerinizi yönetmek: Taleplerinizi karşılamak ve
                        yönetmek için.
                      </li>
                      <li>
                        İş transferleri için: Bilgilerinizi, herhangi bir
                        birleşme, elden çıkarma, yeniden yapılandırma, yeniden
                        organizasyon, fesih veya diğer satış veya transfer
                        işlemlerinde değerlendirmek veya yürütmek için
                        kullanabiliriz.
                      </li>
                      <li>
                        Diğer amaçlar için: Bilgilerinizi, veri analizi,
                        kullanım eğilimlerini belirleme, tanıtım
                        kampanyalarımızın etkinliğini belirleme ve Hizmetimizi,
                        ürünlerimizi, hizmetlerimizi, pazarlama ve deneyiminizi
                        değerlendirme ve geliştirme gibi diğer amaçlar için
                        kullanabiliriz.
                      </li>
                    </ul>
                  </div>
                  <div id="data_storage">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Kişisel Verilerin Saklanması
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Şirket, Kişisel Verilerinizi yalnızca bu Gizlilik
                      Politikasında belirtilen amaçlar için gerekli olduğu
                      sürece saklayacaktır. Yasal yükümlülüklerimize uymak
                      (örneğin, geçerli yasalara uymak için verilerinizi
                      saklamamız gerektiğinde), anlaşmazlıkları çözmek ve yasal
                      anlaşmalarımızı ve politikalarımızı uygulamak için Kişisel
                      Verilerinizi saklayacağız ve kullanacağız.
                    </p>
                  </div>
                  <div id="data_disclosure">
                    <h2
                      className="font-sans"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '20px',
                      }}>
                      Kişisel Verilerin Açıklanması
                    </h2>
                    <div
                      className="update-date"
                      style={{
                        fontWeight: 'bold',
                        color: '#333',
                      }}>
                      SON GÜNCELLEME: 07 Temmuz 2024
                    </div>
                    <p style={{lineHeight: '1.6', color: '#333'}}>
                      Şirket, Kişisel Verilerinizi aşağıdaki durumlarda iyi
                      niyetle açıklayabilir:
                    </p>
                    <ul>
                      <li>Yasal bir yükümlülüğe uymak</li>
                      <li>
                        Şirketin haklarını veya mülkiyetini korumak ve savunmak
                      </li>
                      <li>
                        Hizmet ile bağlantılı olası yanlış davranışları önlemek
                        veya araştırmak
                      </li>
                      <li>
                        Hizmet kullanıcılarının veya kamunun kişisel güvenliğini
                        korumak
                      </li>
                      <li>Yasal sorumluluğa karşı korumak</li>
                    </ul>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default PrivacyPolicy;
