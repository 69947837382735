import React, {useState} from 'react';
import {DataTable} from 'mantine-datatable';
import {useSelector} from 'react-redux';
import {selectBusiness} from '../../store/selectors/businessSelectors';
import useQrLogs from '../../hooks/useQrLogs';

const QRUsersTable: React.FC = () => {
  const business = useSelector(selectBusiness);
  const {userStats} = useQrLogs();

  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);

  const handleUserClick = (userId: string) => {
    setSelectedUser(userId);
  };

  const usersData = Object.entries(userStats).map(([userId, stats]) => ({
    userId,
    totalPoints: stats.totalPoints,
    freeCoffeeCount: stats.freeCoffeeCount,
  }));

  return (
    <div>
      <div className="panel mt-6">
        <h6 className="flex justify-center font-extrabold mt-1 mb-4 font-xl">
          Kullanıcı İstatistikleri
        </h6>
        <div className="datatables">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={usersData}
            columns={[
              {accessor: 'userId', title: 'User ID'},
              {accessor: 'totalPoints', title: 'Total CO Points'},
              {accessor: 'freeCoffeeCount', title: 'Free Coffee Count'},
            ]}
            onRowClick={row => handleUserClick(row.userId)}
            totalRecords={usersData.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={p => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            paginationText={({from, to, totalRecords}) =>
              `Showing ${from} to ${to} of ${totalRecords} entries`
            }
          />
        </div>
      </div>

      {selectedUser && (
        <div className="panel mt-6">
          <h6 className="flex justify-center font-extrabold mt-1 mb-4 font-xl">
            Kullanıcı {selectedUser} için Son 20 QR Logu
          </h6>
          <div className="datatables">
            <DataTable
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover"
              records={userStats[selectedUser].logs.slice(0, 20)}
              columns={[
                {accessor: 'businessId', title: 'Business ID'},
                {accessor: 'addedPoints', title: 'Added Points'},
                {
                  accessor: 'date',
                  title: 'Date',
                  render: record => new Date(record.date).toLocaleDateString(),
                },
                {accessor: 'transactionType', title: 'Transaction Type'},
                {accessor: 'currentPoints', title: 'Current Points'},
              ]}
              totalRecords={userStats[selectedUser].logs.length}
              recordsPerPage={pageSize}
              page={page}
              onPageChange={p => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              minHeight={200}
              paginationText={({from, to, totalRecords}) =>
                `Showing ${from} to ${to} of ${totalRecords} entries`
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default QRUsersTable;
