// reducers/businessSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessType } from '../../types/business';

interface BusinessState {
  token: string | null;
  business: BusinessType | null;
}

const initialState: BusinessState = {
  token: null,
  business: null,
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness: (state, action: PayloadAction<BusinessState>) => {
      state.token = action.payload.token;
      state.business = action.payload.business;
    },
    clearBusiness: (state) => {
      state.token = null;
      state.business = null;
    },
  },
});

export const { setBusiness, clearBusiness } = businessSlice.actions;
export default businessSlice.reducer;
