import {DataTable} from 'mantine-datatable';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setPageTitle} from '../../store/themeConfigSlice';
import {QRLog} from '../../types/qrlog';

interface QRLogsTableProps {
  qrLogs: QRLog[];
  title: string;
}

const QRLogsTable: React.FC<QRLogsTableProps> = ({qrLogs, title}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('QR Logs Table'));
  }, [dispatch]);

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [100, 200, 300, 500, 800];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = qrLogs.slice(0, pageSize);
  const [recordsData, setRecordsData] = useState(initialRecords);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData(qrLogs.slice(from, to));
  }, [page, pageSize, qrLogs]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const sortedLogs = qrLogs.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  return (
    <div>
      <div className="panel mt-6">
        <h6 className="flex justify-center font-extrabold mt-1 mb-4 font-xl">
          {title}
        </h6>
        <div className="datatables">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
              {accessor: 'userId', title: 'User ID'},
              {accessor: 'businessId', title: 'Business ID'},
              {accessor: 'addedPoints', title: 'Added Points'},
              {
                accessor: 'date',
                title: 'Date',
                render: record => formatDate(record.date),
                sortable: true,
              },
              {accessor: 'transactionType', title: 'Transaction Type'},
              {accessor: 'currentPoints', title: 'Current Points'},
            ]}
            totalRecords={qrLogs.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={p => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            paginationText={({from, to, totalRecords}) =>
              `Showing ${from} to ${to} of ${totalRecords} entries`
            }
          />
        </div>
      </div>
    </div>
  );
};

export default QRLogsTable;
