import React from 'react';
import {Link} from 'react-router-dom';

const CallToActionTwo = () => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title">
          <h6>Şimdi Abone Ol</h6>
          <h2>Bültenimize Abone Ol</h2>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onClick={handleSubmit}>
            {/* <input type="text" placeholder="Email address" /> */}
            <input type="text" placeholder="E-posta adresi" />
            {/* <button>Subscribe</button> */}
            <button>Abone Ol</button>
          </form>
          {/* End form */}
          <p className="font-rubik">
            {/* Already a member? <Link to="login">Sign in.</Link> */}
            Şimdiden bir üye misiniz?{' '}
            <Link to="/admin/login">Giriş yapın.</Link>
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionTwo;
