import React, {useState} from 'react';
import QRLogsTable from '../components/common/QRLogsTable';
import useBusinessDetails from '../hooks/useBusinessDetails';
import useQrLogs from '../hooks/useQrLogs';
import useLoyaltyCard from '../hooks/useLoyaltyCard';
import LoyaltyCard from '../components/common/LoyaltyCard';
import BusinessCard from '../components/common/BusinessCard';
import ColumnChart from '../components/charts/ColumnChart';
import DonutChart from '../components/charts/DonutChart';
import {useSelector} from 'react-redux';
import {selectBusiness} from '../store/selectors/businessSelectors';
import QRUsersTable from '../components/common/QRUsersTable';
import CustomAlert from '../components/common/CustomAlert';

const AllQRLogs: React.FC = () => {
  const {qrLogs} = useQrLogs();

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full">
        <QRLogsTable qrLogs={qrLogs || []} title="QR Logları" />
      </div>
    </div>
  );
};

export default AllQRLogs;
