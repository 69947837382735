import React from 'react';
import {Helmet} from 'react-helmet';
import HeaderThree from './HeaderThree';
import HeroBannerThree from './HeroBannerThree';
import BrandThree from './BrandThree';
import FaqClassic from './FaqClassic';
import CopyRight from './CopyRight';
import FooterThree from './FooterThree';
import CallToActionTwo from './CallToActionTwo';
import PricingThree from './PricingThree';
import FeatureFour from './FeatureFour';

import {ReactComponent as LineShape3} from '../../assets/images/shape/line-shape-3.svg';
import {ReactComponent as Shape107} from '../../assets/images/shape/107.svg';
import {ReactComponent as Shape108} from '../../assets/images/shape/108.svg';
import {ReactComponent as Shape109} from '../../assets/images/shape/109.svg';
import {ReactComponent as Shape110} from '../../assets/images/shape/110.svg';
import {ReactComponent as Shape111} from '../../assets/images/shape/111.svg';
import {ReactComponent as Shape112} from '../../assets/images/shape/112.svg';
import {ReactComponent as LineShape10} from '../../assets/images/shape/line-shape-10.svg';
import {ReactComponent as Shape86} from '../../assets/images/shape/86.svg';
import {ReactComponent as Shape87} from '../../assets/images/shape/87.svg';
import {ReactComponent as Shape88} from '../../assets/images/shape/88.svg';
import {ReactComponent as Shape89} from '../../assets/images/shape/89.svg';
import {ReactComponent as Shape90} from '../../assets/images/shape/90.svg';
import {ReactComponent as Shape91} from '../../assets/images/shape/91.svg';
import {ReactComponent as LineShape2} from '../../assets/images/shape/line-shape-2.svg';

const LandingScreen = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Coferta</title>
      </Helmet>
      <HeaderThree />
      <HeroBannerThree />
      <div className="fancy-feature-eight mt-110 md-mt-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 m-auto">
              <div className="title-style-four text-center mb-80 md-mb-30">
                <h2 data-aos="fade-up" data-aos-duration="1200">
                  Coferta ekosistemine dahil ol, dijital dünyaya merhaba de!
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-11 m-auto">
              <FeatureFour />
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-section-three mt-250 mb-100 md-mt-150 md-mb-150">
        <Shape107 className="shapes shape-one" />
        <Shape108 className="shapes shape-two" />
        <Shape109 className="shapes shape-three" />
        <Shape110 className="shapes shape-four" />
        <Shape111 className="shapes shape-five" />
        <Shape112 className="shapes shape-six" />
        <div className="container">
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6>Fiyatlandırma</h6>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <h2>Rakipsiz fiyatlar, sözleşme yok, basit ve kolay</h2>
              </div>
            </div>
          </div>
          <div className="pricing-table-area-three">
            <PricingThree />
          </div>
        </div>
      </div>
      <div className="useable-tools-section-three pt-0 pb-200 md-pt-100 md-pb-80 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-four">
                <h2>Kimler Coferta ile birlikte?</h2>
              </div>
              <p className="sub-text">
                Coferta'nın desteklediği işletmeleri keşfedin, avantajlı
                kampanyalarla büyüyün ve dijital ekosistemimizde yerinizi alın.
              </p>
            </div>
          </div>
        </div>
        <div
          className="
        logo-wrapper
        d-flex
        flex-wrap
        justify-content-center
        align-items-center
      ">
          <BrandThree />
        </div>
      </div>
      <div className="faq-classic with-bg">
        <Shape86 className="shapes shape-one" />
        <Shape87 className="shapes shape-two" />
        <Shape88 className="shapes shape-three" />
        <Shape89 className="shapes shape-four" />
        <Shape90 className="shapes shape-five" />
        <Shape91 className="shapes shape-six" />
        <div className="container">
          <div className="title-style-four text-center mb-100 md-mb-70">
            <div className="row">
              <div className="col-lg-7 m-auto">
                <h6>SSS</h6>
                <h2>
                  <span>Sizin Merak Ettikleriniz</span>
                </h2>
              </div>
            </div>
          </div>
          <FaqClassic />
        </div>
      </div>
      <div className="fancy-short-banner-four">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionTwo />
          </div>
        </div>
      </div>
      <footer className="theme-footer-three pt-100">
        <div className="top-footer">
          <div className="container">
            <FooterThree />
          </div>
        </div>
        <div className="container">
          <div className="bottom-footer-content">
            <div className="row">
              <div className="col-lg-8 ms-auto">
                <CopyRight />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingScreen;
