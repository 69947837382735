import {useState, useEffect} from 'react';
import {CofertaAPI} from '../services';
import {BusinessType} from '../types/business';
import {useSelector} from 'react-redux';
import {
  selectBusinessToken,
  selectBusiness,
} from '../store/selectors/businessSelectors';

const useBusinessDetails = () => {
  const [businessDetails, setBusinessDetails] = useState<BusinessType | null>(
    null,
  );
  const token = useSelector(selectBusinessToken);
  const business = useSelector(selectBusiness);

  useEffect(() => {
    if (!token || !business) {
      return;
    }

    const fetchBusinessDetails = async () => {
      try {
        const response = await CofertaAPI.getBusinessByBusinessId(token, {
          businessId: business._id,
        });
        if (response.status === 200 && response.data) {
          setBusinessDetails(response.data);
        } else {
          console.error(
            'İşletme bilgilerini alırken bir hata oluştu:',
            response,
          );
        }
      } catch (error) {
        console.error('İşletme bilgilerini alırken bir hata oluştu:', error);
      }
    };

    fetchBusinessDetails();
  }, [token, business]);

  return businessDetails;
};

export default useBusinessDetails;
