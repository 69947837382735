import React from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as Ils09} from '../../assets/images/assets/ils_09.svg';
import {ReactComponent as Shape68} from '../../assets/images/shape/68.svg';
import {ReactComponent as Shape69} from '../../assets/images/shape/69.svg';
import {ReactComponent as Shape70} from '../../assets/images/shape/70.svg';
import {ReactComponent as Shape71} from '../../assets/images/shape/71.svg';
import {ReactComponent as Shape72} from '../../assets/images/shape/72.svg';
import {ReactComponent as Shape73} from '../../assets/images/shape/73.svg';
import {ReactComponent as Shape74} from '../../assets/images/shape/74.svg';
import {ReactComponent as Shape75} from '../../assets/images/shape/75.svg';
import {ReactComponent as Shape76} from '../../assets/images/shape/76.svg';
import {ReactComponent as Shape77} from '../../assets/images/shape/77.svg';

const HeroBannerThree = () => {
  return (
    <div className="hero-banner-three">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
            <h1 className="font-rubik">İşletmeni Coferta ile dijitalleştir.</h1>
          </div>
          {/* End .col */}

          <div className="col-xl-8 col-lg-9 m-auto">
            <p className="sub-text font-rubik">
              İşletmeleri müşteri sadakati ve etkileşimi konusunda destekleyerek
              onlara ulaşmalarını sağlayan bir platform sunuyoruz
            </p>
          </div>
          {/* End .col */}
        </div>
        {/* End .row */}

        <div
          className="search-filter-form"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <a
            href="admin/register"
            className="theme-btn-nine"
            style={{
              fontWeight: 700,
            }}
            data-aos="fade-up"
            data-aos-delay="200">
            Şimdi Başla
          </a>
        </div>
        {/* End search-filter-from */}

        <p className="sing-in-call">
          Zaten Kayıtlı Mısınız?{' '}
          <Link
            to="/admin/login"
            className="font-rubik"
            style={{
              fontWeight: 500,
            }}>
            Giriş Yap
          </Link>
          .
        </p>

        <Ils09 className="illustration" />
      </div>
      {/* /.container */}

      <Shape68 className="shapes shape-one" />
      <Shape69 className="shapes shape-two" />
      <Shape70 className="shapes shape-three" />
      <Shape71 className="shapes shape-four" />
      <Shape72 className="shapes shape-five" />
      <Shape73 className="shapes shape-six" />
      <Shape74 className="shapes shape-seven" />
      <Shape75 className="shapes shape-eight" />
      <Shape76 className="shapes shape-nine" />
      <Shape77 className="shapes shape-ten" />
    </div>
    // End hero-banner-three
  );
};

export default HeroBannerThree;
