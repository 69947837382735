import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useState, useMemo, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useAppDispatch} from '../store/hooks';
import {setBusiness, clearBusiness} from '../store/reducers/businessSlice';
import {CofertaAPI} from '../services/API';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Map from '../components/common/Map';

const RegisterScreen = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm();
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLocationSelect = ({lat, lng, address, placeId, name}: any) => {
    setLocation({
      googleMapsUrl: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
      address,
      placeId,
      name,
    });
    setValue('businessName', name);
  };

  const memoizedMap = useMemo(() => {
    return <Map onSelectLocation={handleLocationSelect} />;
  }, []);

  const onSubmit = async (data: any) => {
    if (!location) {
      setError('Lütfen haritada bir konum seçin.');
      return;
    }

    const payload = {
      email: data.email,
      password: data.password,
      primaryPhoneNumber: data.phone,
      googleMapsUrl: location.googleMapsUrl,
      address: location.address,
      placeId: location.placeId,
      name: location.name,
    };

    try {
      if (!process.env.REACT_APP_API_KEY) {
        throw new Error('API_KEY çevre değişkenlerinde eksik.');
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}auth/create-business`,
        {
          method: 'POST',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP hatası! durum: ${response.status}`);
      }

      const result = await response.json();
    } catch (error) {
      console.error('Form gönderilirken hata oluştu:', error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center min-h-screen bg-cover bg-center p-6">
      <div className="panel sm:w-[480px] m-6 max-w-lg w-full">
        <h2 className="font-bold text-2xl mb-3">Kayıt Ol</h2>
        <p className="mb-7">
          İşletmenizin konumunu haritada seçin ve kayıt formunu doldurun
        </p>
        <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="businessName"
              style={{
                marginBottom: '0.25rem',
              }}>
              İşletme Adı
            </label>
            <input
              id="businessName"
              type="text"
              className="form-input bg-gray-100 cursor-not-allowed"
              placeholder="Haritada bir işletme seçin"
              {...register('businessName')}
              readOnly
              tabIndex={-1}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              style={{
                marginBottom: '0.25rem',
              }}>
              E-posta
            </label>
            <input
              id="email"
              type="email"
              className="form-input"
              placeholder="E-postanızı Girin"
              {...register('email', {required: true})}
            />
            {errors.email && <p className="text-red-500">E-posta gerekli</p>}
          </div>
          <div>
            <label
              htmlFor="password"
              style={{
                marginBottom: '0.25rem',
              }}>
              Şifre
            </label>
            <input
              id="password"
              type="password"
              className="form-input"
              placeholder="Şifrenizi Girin"
              {...register('password', {required: true})}
            />
            {errors.password && <p className="text-red-500">Şifre gerekli</p>}
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              style={{
                marginBottom: '0.25rem',
              }}>
              Şifreyi Onaylayın
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="form-input"
              placeholder="Şifreyi Onaylayın"
              {...register('confirmPassword', {required: true})}
            />
            {errors.confirmPassword && (
              <p className="text-red-500">Şifre onayı gerekli</p>
            )}
          </div>
          <div>
            <label
              htmlFor="phone"
              style={{
                marginBottom: '0.25rem',
              }}>
              Telefon Numarası
            </label>
            <input
              id="phone"
              type="tel"
              className="form-input"
              placeholder="Telefon Numaranızı Girin"
              {...register('phone', {required: true})}
            />
            {errors.phone && (
              <p className="text-red-500">Telefon numarası gerekli</p>
            )}
          </div>
          {error && (
            <div className="text-center text-red-500">
              <p>{error}</p>
            </div>
          )}
          <button type="submit" className="btn btn-primary w-full">
            KAYIT OL
          </button>
          <p className="text-center">
            Zaten bir hesabınız var mı?{' '}
            <Link
              to="/admin/login"
              className="font-bold text-primary hover:underline ltr:ml-1 rtl:mr-1">
              Giriş Yap
            </Link>
          </p>
        </form>
      </div>
      <div className="md:w-1/2 w-full h-64 md:h-auto p-6">{memoizedMap}</div>
    </div>
  );
};

export default RegisterScreen;
