import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReloadState {
  needsReload: boolean;
}

const initialState: ReloadState = {
  needsReload: false,
};

const reloadSlice = createSlice({
  name: 'reload',
  initialState,
  reducers: {
    setNeedsReload: (state, action: PayloadAction<boolean>) => {
      state.needsReload = action.payload;
    },
  },
});

export const { setNeedsReload } = reloadSlice.actions;
export default reloadSlice.reducer;
