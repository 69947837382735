import React, {useEffect} from 'react';
import {Route, BrowserRouter, Routes, Navigate} from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import Dashboard from './screens/Dashboard';
import LandingScreen from './screens/landingPage/LandingScreen';
import {useSelector} from 'react-redux';
import {selectBusinessToken} from './store/selectors/businessSelectors';
import CommonLayout from './components/CommonLayout';
import './assets/main.scss';
import 'bootstrap/dist/js/bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PrivacyPolicy from './screens/landingPage/PrivacyPolicy';
import TermsConditions from './screens/landingPage/TermsConditions';
import AllQRLogs from './screens/AllQRLogs';
import UserQRLogs from './screens/UserQRLogs';
import CampaignScreen from './screens/CampaignScreen';
import BusinessProfile from './screens/BusinessProfile';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener('load', AOS.refresh);

  const token = useSelector(selectBusinessToken);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/admin/login" element={<LoginScreen />} />
        <Route path="/admin/register" element={<RegisterScreen />} />
        <Route
          path="/admin/*"
          element={token ? <SecuredRoutes /> : <Navigate to="/admin/login" />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function SecuredRoutes() {
  return (
    <CommonLayout>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="all-qr-logs" element={<AllQRLogs />} />
        <Route path="user-qr-logs" element={<UserQRLogs />} />
        <Route path="campaigns" element={<CampaignScreen />} />
        <Route path="profile" element={<BusinessProfile />} />
        {/* Diğer güvenli rotaları buraya ekleyin */}
      </Routes>
    </CommonLayout>
  );
}
