import React from 'react';
import IconStar from '../Icon/IconStar';
import CustomAlert from './CustomAlert';

interface LoyaltyCardProps {
  businessName: string;
  freeThreshold: number;
  onFreeThresholdChange: (value: number) => void;
  onCreateLoyaltyCard: () => void;
  loyaltyCardExists: boolean;
}

const LoyaltyCard: React.FC<LoyaltyCardProps> = ({
  businessName,
  freeThreshold,
  onFreeThresholdChange,
  onCreateLoyaltyCard,
  loyaltyCardExists,
}) => {
  return (
    <div className="mb-1 flex items-center justify-center">
      <div className="max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none">
        <div className="py-7 px-6">
          <h5 className="text-[#3b3f5c] text-xl font-semibold mb-4 dark:text-white-light">
            {businessName} Sadakat Kartı
          </h5>
          {loyaltyCardExists ? (
            <p className="text-white-dark">
              Müşterileriniz {freeThreshold} alışveriş sonrasında ücretsiz bir
              ürün kazanacaklar!
            </p>
          ) : (
            <>
              <p className="text-white-dark">
                Ücretsiz ürün kazanmak için alışveriş yapılacak adeti
                belirleyin;
                <input
                  type="number"
                  value={freeThreshold}
                  onChange={e => onFreeThresholdChange(Number(e.target.value))}
                  className="form-input mt-2"
                  min="1"
                />
              </p>
              <button
                onClick={onCreateLoyaltyCard}
                className="btn btn-primary mt-3">
                Sadakat Kartı Oluştur
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoyaltyCard;
