import {useState, useEffect} from 'react';
import {CofertaAPI} from '../services';
import {useSelector} from 'react-redux';
import {
  selectBusinessToken,
  selectBusiness,
} from '../store/selectors/businessSelectors';

const useLoyaltyCard = () => {
  const [loyaltyCard, setLoyaltyCard] = useState<{
    businessName: string;
    freeThreshold: number;
  } | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<'success' | 'error' | null>(null);
  const token = useSelector(selectBusinessToken);
  const business = useSelector(selectBusiness);

  useEffect(() => {
    if (!token || !business) {
      return;
    }

    const fetchLoyaltyCard = async () => {
      try {
        const response = await CofertaAPI.getLoyaltyCard(token, {
          businessId: business._id,
        });
        if (response.status === 200 && response.data) {
          setLoyaltyCard({
            businessName: business.name,
            freeThreshold: response.data.freeThreshold,
          });
        } else {
          console.error('Loyalty kartı alırken bir hata oluştu:', response);
        }
      } catch (error) {
        console.error('Loyalty kartı alırken bir hata oluştu:', error);
      }
    };

    fetchLoyaltyCard();
  }, [token, business]);

  const handleCreateLoyaltyCard = async (
    freeThreshold: number,
  ): Promise<void> => {
    if (!business) {
      return;
    }

    try {
      const response = await CofertaAPI.createLoyaltyCard(token, {
        businessId: business._id,
        freeThreshold,
      });

      if (response.status === 201 && response.data) {
        setAlertMessage('Loyalty kartı başarıyla oluşturuldu');
        setAlertType('success');
        setLoyaltyCard({
          businessName: business.name,
          freeThreshold: response.data.freeThreshold,
        });
      } else {
        setAlertMessage('Loyalty kartı oluşturulurken bir hata oluştu');
        setAlertType('error');
      }
    } catch (error) {
      setAlertMessage('Loyalty kartı oluşturulurken bir hata oluştu');
      setAlertType('error');
    }
  };

  return {
    handleCreateLoyaltyCard,
    loyaltyCard,
    alertMessage,
    alertType,
    setAlertMessage,
  };
};

export default useLoyaltyCard;
