import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {useAppDispatch} from '../store/hooks';
import {setBusiness, clearBusiness} from '../store/reducers/businessSlice';
import {CofertaAPI} from '../services/API';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForm = async (e: any) => {
    e.preventDefault();

    if (email === '') {
      setError('E-posta gerekli');
    } else if (password === '') {
      setError('Şifre gerekli');
    } else {
      setError(null);
      handleLogin();
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const loginPayload = {
        email,
        password,
      };
      const response = await CofertaAPI.loginBusiness(loginPayload);
      if (response.status === 200 && response.data) {
        dispatch(
          setBusiness({
            token: response.data.token,
            business: response.data.business,
          }),
        );
      }
      navigate('/admin/dashboard');
    } catch (error: any) {
      if (error.response) {
        setError(error.response.error);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cover bg-center">
      <div className="panel sm:w-[480px] m-6 max-w-lg w-full">
        <h2 className="font-bold text-2xl mb-3">Giriş Yap</h2>
        <p className="mb-7">Giriş yapmak için e-posta ve şifrenizi girin</p>
        <form className="space-y-5" onSubmit={handleForm}>
          <div>
            <label
              htmlFor="email"
              style={{
                marginBottom: '0.25rem',
              }}>
              E-posta
            </label>
            <input
              id="email"
              type="email"
              className="form-input"
              placeholder="E-postanızı Girin"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              style={{
                marginBottom: '0.25rem',
              }}>
              Şifre
            </label>
            <input
              id="password"
              type="password"
              className="form-input"
              placeholder="Şifrenizi Girin"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <div className="text-center text-red-500">
              <p>{error}</p>
            </div>
          )}
          <button type="submit" className="btn btn-primary w-full">
            GİRİŞ YAP
          </button>
        </form>
        <div className="relative my-7 h-5 text-center before:w-full before:h-[1px] before:absolute before:inset-0 before:m-auto before:bg-[#ebedf2] dark:before:bg-[#253b5c]">
          <div className="font-bold text-white-dark bg-white dark:bg-black px-2 relative z-[1] inline-block"></div>
        </div>
        <p className="text-center">
          Hesabınız yok mu?
          <Link
            to="/admin/register"
            className="font-bold text-primary hover:underline ltr:ml-1 rtl:mr-1">
            Kayıt Ol
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginScreen;
