import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import MegaMenu from './mega-menu/MegaMenu';
import MegaMenuMobile from './mega-menu/MegaMenuMobile';
import CofertaLogo from '../../assets/images/logo/coferta_logo_whitebackground.png';
import './styles.css';

const HeaderThree = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <>
      <div
        className={
          navbar
            ? 'theme-main-menu sticky-menu theme-menu-three bg-none fixed'
            : 'theme-main-menu sticky-menu theme-menu-three bg-none'
        }>
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img
                src={CofertaLogo}
                alt="coferta logo"
                style={{width: '98px'}}
              />
            </Link>
          </div>

          <div className="d-lg-flex justify-content-between align-items-center responsive">
            <MegaMenu />
            <ul className="right-widget user-login-button d-flex align-items-center justify-content-center">
              <li>
                <Link
                  to="/admin/login"
                  style={{
                    fontWeight: 700,
                  }}
                  className="signIn-action d-flex align-items-center">
                  <span>Giriş Yap</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/register"
                  style={{
                    fontWeight: 700,
                  }}
                  className="signUp-action d-flex align-items-center">
                  <span>Şimdi Başla</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <MegaMenuMobile />
        {/* 	End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default HeaderThree;
