import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ColumnChartProps {
  series: any[];
  categories: string[];
  title: string;
}

const ColumnChart: React.FC<ColumnChartProps> = ({
  series,
  categories,
  title,
}) => {
  const options = {
    chart: {
      height: 300,
      type: 'bar' as 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#805dca', '#e7515a'],
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
      formatter: function (val: any) {
        return val;
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    grid: {
      borderColor: '#e0e6ed',
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        color: '#e0e6ed',
      },
    },
    yaxis: {
      labels: {
        offsetX: 0,
      },
    },
    tooltip: {
      theme: 'light',
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  };

  return (
    <div className="panel mt-6 w-4/5">
      <h6 className="flex justify-center font-extrabold mt-2 mb-2 font-xl">
        {title}
      </h6>
      {series[0].data.length === 0 ? (
        <p>Veri bulunamadı.</p>
      ) : (
        <ReactApexChart
          series={series}
          options={options}
          className="rounded-lg bg-white dark:bg-black overflow-hidden"
          type="bar"
          height={300}
        />
      )}
    </div>
  );
};

export default ColumnChart;
