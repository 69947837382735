import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectBusiness,
  selectBusinessToken,
} from '../store/selectors/businessSelectors';
import {CofertaAPI} from '../services/API';
import CustomAlert from '../components/common/CustomAlert';

const BusinessProfile: React.FC = () => {
  const business = useSelector(selectBusiness);
  const token = useSelector(selectBusinessToken);
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleForm = async (e: any) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Yeni şifre ve onay şifresi eşleşmiyor');
      setIsAlertOpen(true);
      return;
    }

    try {
      setLoading(true);

      if (!business) {
        throw new Error('İşletme bilgileri alınamadı');
      }

      const payload = {
        businessId: business._id,
        currentPassword,
        newPassword,
      };
      const response = await CofertaAPI.changeBusinessPassword(token, payload);
      if (response.status === 200) {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setSuccess('Şifre başarıyla değiştirildi');
        setError(null);
      } else {
        setError(response.data.message);
      }
    } catch (error: any) {
      setError('Şifre değiştirme işlemi sırasında bir hata oluştu');
    } finally {
      setLoading(false);
      setIsAlertOpen(true);
    }
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
    setError(null);
    setSuccess(null);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cover bg-center">
      <div className="panel sm:w-[480px] max-w-lg w-full">
        <h2 className="font-bold text-2xl mb-3">Şifre Değiştir</h2>
        <form className="space-y-5" onSubmit={handleForm}>
          <div>
            <label htmlFor="currentPassword" style={{marginBottom: '0.25rem'}}>
              Mevcut Şifre
            </label>
            <input
              id="currentPassword"
              type="password"
              className="form-input"
              placeholder="Mevcut şifrenizi girin"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="newPassword" style={{marginBottom: '0.25rem'}}>
              Yeni Şifre
            </label>
            <input
              id="newPassword"
              type="password"
              className="form-input"
              placeholder="Yeni şifrenizi girin"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" style={{marginBottom: '0.25rem'}}>
              Yeni Şifreyi Onaylayın
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="form-input"
              placeholder="Yeni şifrenizi tekrar girin"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary w-full"
            disabled={loading}>
            {loading ? 'Yükleniyor...' : 'Şifreyi Değiştir'}
          </button>
        </form>
      </div>
      <CustomAlert
        isOpen={isAlertOpen}
        onClose={closeAlert}
        onConfirm={closeAlert}
        title={error ? 'Hata' : 'Başarı'}
        message={error ?? success ?? ''}
        confirmText="Tamam"
        showCancelButton={false}
        confirmButtonClass={
          error ? 'btn btn-outline-danger' : 'btn btn-primary'
        }
      />
    </div>
  );
};

export default BusinessProfile;
