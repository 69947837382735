import {
  APIRequestMethod,
  APIRequestOptions,
  TokenJWTType,
  CofertaAPIType,
} from './type';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const buildQueryString = (params: {[key: string]: any}) => {
  return Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};

const handlePostLogoutActions = async () => {
  //   await CookieManager.clearAll();
  //   store.dispatch(userActions.setUserToken(''));
  //   store.dispatch(userActions.logout());
  //   store.dispatch(registrationActions.clearData());
  //   RootNavigation.reset({
  //     index: 1,
  //     routes: [{name: 'Registration'}],
  //   });
};

const logoutAPIcall = async () => {
  const token = localStorage.getItem('token');
  try {
    const url = `${API_BASE_URL}logout`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
      'api-key': API_KEY,
      Authorization: `Bearer ${token}`,
    };

    const options: any = {
      method: 'POST',
      headers,
    };

    const response = await fetch(url, options);
    if (!response.ok) throw new Error('Logout başarısız.');

    await handlePostLogoutActions();
  } catch (error) {
    console.error('Logout sırasında bir hata oluştu:', error);
  }
};

const headerFetchCalling = async (
  method: APIRequestMethod,
  url: string,
  opts?: APIRequestOptions,
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    dataType: 'json',
    'api-key': API_KEY,
    ...(opts?.token && {Authorization: 'Bearer ' + opts.token}),
  };

  const options = {
    method,
    headers,
    ...(opts?.params && {body: JSON.stringify(opts.params)}),
  };

  try {
    const response = await fetch(url, options);
    const json = await response.json();
    if (response.ok) {
      return json;
    } else if (response.status === 502) {
      return response;
    } else if (response.status === 401) {
      await logoutAPIcall();
    } else {
      return json;
    }
  } catch (error) {
    console.error('API çağrısı sırasında bir hata oluştu:', error);
    throw error;
  }
};

const sendAPIRequest = async (
  route: string,
  method: APIRequestMethod,
  opts?: APIRequestOptions,
) => {
  const url = `${API_BASE_URL}${route}`;
  return await headerFetchCalling(method, url, opts);
};

export const CofertaAPI: CofertaAPIType = {
  loginBusiness: async params => {
    return await sendAPIRequest('auth/login-business', 'POST', {params});
  },

  logout: async token => {
    return await sendAPIRequest('logout', 'POST', {token});
  },

  getQrLogs: async (token, {businessId, startDate, endDate}) => {
    const params = {startDate, endDate};
    const queryString = buildQueryString(params);
    const route = `qrlogs/${businessId}?${queryString}`;
    return await sendAPIRequest(route, 'GET', {token});
  },

  createLoyaltyCard: async (token, params) => {
    return await sendAPIRequest('loyalty-card', 'POST', {token, params});
  },

  getLoyaltyCard: async (token, {businessId}) => {
    return await sendAPIRequest(`loyalty-card/${businessId}`, 'GET', {token});
  },

  getBusinessByBusinessId: async (token, {businessId}) => {
    return await sendAPIRequest(`business/${businessId}`, 'GET', {token});
  },

  createCampaign: async (token, params) => {
    return await sendAPIRequest('campaign', 'POST', {token, params});
  },

  getAllCampaigns: async token => {
    return await sendAPIRequest('campaigns', 'GET', {token});
  },

  getCampaignById: async (token, {id}) => {
    return await sendAPIRequest(`campaigns/${id}`, 'GET', {token});
  },

  updateCampaign: async (token, params) => {
    const {id, ...updateParams} = params;
    return await sendAPIRequest(`campaigns/${id}`, 'PUT', {
      token,
      params: updateParams,
    });
  },

  deleteCampaign: async (token, {id}) => {
    return await sendAPIRequest(`campaigns/${id}`, 'DELETE', {token});
  },

  changeBusinessPassword: async (token, params) => {
    return await sendAPIRequest('business/change-password', 'PUT', {
      token,
      params,
    });
  },
};
